import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import logo from "./images/logo.png";
import {Link, useNavigate} from "react-router-dom";
import React, {useState} from "react";

const CustomNavbar2 = () => {
    const [showContact, setShowContact] = useState(false);

    const handleToggle = () => {
        setShowContact(!showContact);
    };

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/subscribe');
    };

    return (
        <Container fluid className="navbar-container">
            <Navbar className="navbar-drop" collapseOnSelect expand="lg" bg="light" variant="light" fixed="top">
                <Navbar.Brand as={Link} className="navbar-brand" to="/">
                    <img className="logo responsive" src={logo} alt="Logo"/>
                </Navbar.Brand>
                <p className="navbar-brand-text">Stick2YourDreams</p>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ul-nav">
                        <Nav.Link as={Link} className="navbar-nav" to="/">Home</Nav.Link>
                        <Nav.Link as={Link} className="navbar-nav" to="/#services">Services</Nav.Link>
                        <Nav.Link as={Link} className="navbar-nav" to="/#about">About</Nav.Link>
                        <NavDropdown title="Contact" id="collasible-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/get-started">Get Started</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/#contact">General Questions</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <div className="dropdown-item" style={{ padding: '0' }}>
                                <button onClick={handleButtonClick}>
                                    Subscribe
                                </button>
                            </div>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Container>
    );
};

export default CustomNavbar2;