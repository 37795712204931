import React, { useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './App.css';

const GetStartedForm = () => {
    const [formData, setFormData] = useState({
        Name: '',
        Email: '',
        Phone: '',
        BusinessType: '',
        BusinessUse: '',
        Goals: '',
        Slogan: '',
        Logo: '',
        Content: '',
        Colors: '',
        Timeframe: '',
        Hosting: ''
    });

    const [currentStep, setCurrentStep] = useState(0);
    const [isPreview, setIsPreview] = useState(false);
    const navigate = useNavigate();

    const steps = [
        'Name',
        'Email',
        'Phone',
        'BusinessType',
        'BusinessUse',
        'Goals',
        'Slogan',
        'Logo',
        'Content',
        'Colors',
        'Timeframe',
        'Hosting'
    ];

    const formatPhoneNumber = (value) => {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;

        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    };

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        if (type === 'radio') {
            setFormData({
                ...formData,
                [name]: value === 'yes'
            });
        } else if (name === 'Phone') {
            setFormData({
                ...formData,
                [name]: formatPhoneNumber(value)
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleNextStep = (e) => {
        e.preventDefault();
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        } else {
            setIsPreview(true);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const handleFinalSubmit = () => {
        fetch('https://formspree.io/f/mqkrrobg', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        }).then(response => {
            if (response.ok) {
                alert('Email sent successfully!\n' +
                    'We will be contacting you through the email that you provided very soon.\n' +
                    'Thank you for choosing Stick2YourDreams');
                setFormData({
                    Name: '',
                    Email: '',
                    Phone: '',
                    BusinessType: '',
                    BusinessUse: '',
                    Goals: '',
                    Slogan: '',
                    Logo: '',
                    Content: '',
                    Colors: '',
                    Timeframe: '',
                    Hosting: ''
                });
                setCurrentStep(0);
                setIsPreview(false);
                window.scrollTo({ top: 0, behavior: 'smooth' });
                navigate('/');
            } else {
                alert('There was an error sending the email. Please try again later.');
                setIsPreview(false);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }).catch(error => {
            console.error('Error sending email:', error);
            alert('There was an error sending the email. Please try again later.');
            setIsPreview(false);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleNextStep(e);
        }
    };

    const handleEdit = () => {
        setIsPreview(false);
    };

    const renderStep = () => {
        switch (steps[currentStep]) {
            case 'Name':
                return (
                    <div className="form-group">
                        <label htmlFor="Name">Name</label>
                        <input
                            type="text"
                            id="Name"
                            name="Name"
                            value={formData.Name}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            required
                            className="form-control"
                        />
                    </div>
                );
            case 'Email':
                return (
                    <div className="form-group">
                        <label htmlFor="Email">Email Address</label>
                        <input
                            type="email"
                            id="Email"
                            name="Email"
                            value={formData.Email}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            required
                            className="form-control"
                        />
                    </div>
                );
            case 'Phone':
                return (
                    <div className="form-group">
                        <label htmlFor="Phone">Phone Number</label>
                        <input
                            type="tel"
                            id="Phone"
                            name="Phone"
                            value={formData.Phone}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            required
                            className="form-control"
                        />
                    </div>
                );
            case 'BusinessType':
                return (
                    <div className="form-group">
                        <label htmlFor="BusinessType">What type of business is this?</label>
                        <input
                            type="text"
                            id="BusinessType"
                            name="BusinessType"
                            value={formData.BusinessType}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            required
                            className="form-control"
                        />
                    </div>
                );
            case 'BusinessUse':
                return (
                    <div className="form-group">
                        <label htmlFor="BusinessUse">Describe your business and its main products or services</label>
                        <textarea
                            id="BusinessUse"
                            name="BusinessUse"
                            value={formData.BusinessUse}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            required
                            className="form-control"
                        />
                    </div>
                );
            case 'Goals':
                return (
                    <div className="form-group">
                        <label htmlFor="Goals">What are your primary goals for the website?</label>
                        <textarea
                            id="Goals"
                            name="Goals"
                            value={formData.Goals}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            required
                            className="form-control"
                        />
                    </div>
                );
            case 'Slogan':
                return (
                    <div className="form-group">
                        <label>Do you have a tagline or slogan?</label>
                        <div className="radio-group">
                            <div className="radio-item">
                                <input
                                    type="radio"
                                    id="SloganYes"
                                    name="Slogan"
                                    value="yes"
                                    checked={formData.Slogan === true}
                                    onChange={handleChange}
                                    required
                                />
                                <label htmlFor="SloganYes">Yes</label>
                            </div>
                            <div className="radio-item">
                                <input
                                    type="radio"
                                    id="SloganNo"
                                    name="Slogan"
                                    value="no"
                                    checked={formData.Slogan === false}
                                    onChange={handleChange}
                                    required
                                />
                                <label htmlFor="SloganNo">No</label>
                            </div>
                        </div>
                    </div>
                );
            case 'Logo':
                return (
                    <div className="form-group">
                        <label>Do you have a logo?</label>
                        <div className="radio-group">
                            <div className="radio-item">
                                <input
                                    type="radio"
                                    id="LogoYes"
                                    name="Logo"
                                    value="yes"
                                    checked={formData.Logo === true}
                                    onChange={handleChange}
                                    required
                                />
                                <label htmlFor="LogoYes">Yes</label>
                            </div>
                            <div className="radio-item">
                                <input
                                    type="radio"
                                    id="LogoNo"
                                    name="Logo"
                                    value="no"
                                    checked={formData.Logo === false}
                                    onChange={handleChange}
                                    required
                                />
                                <label htmlFor="LogoNo">No</label>
                            </div>
                        </div>
                    </div>
                );
            case 'Content':
                return (
                    <div className="form-group">
                        <label htmlFor="Content">How much content do you have?</label>
                        <select
                            id="Content"
                            name="Content"
                            value={formData.Content}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            required
                            className="form-control"
                        >
                            <option value="">Select an option</option>
                            <option value="All">All</option>
                            <option value="Some">Some</option>
                            <option value="None">None</option>
                        </select>
                    </div>
                );
            case 'Colors':
                return (
                    <div className="form-group">
                        <label>Do you have a color scheme?</label>
                        <div className="radio-group">
                            <div className="radio-item">
                                <input
                                    type="radio"
                                    id="ColorsYes"
                                    name="Colors"
                                    value="yes"
                                    checked={formData.Colors === true}
                                    onChange={handleChange}
                                    required
                                />
                                <label htmlFor="ColorsYes">Yes</label>
                            </div>
                            <div className="radio-item">
                                <input
                                    type="radio"
                                    id="ColorsNo"
                                    name="Colors"
                                    value="no"
                                    checked={formData.Colors === false}
                                    onChange={handleChange}
                                    required
                                />
                                <label htmlFor="ColorsNo">No</label>
                            </div>
                        </div>
                    </div>
                );
            case 'Timeframe':
                return (
                    <div className="form-group">
                        <label htmlFor="Timeframe">What is the timeframe that you would like the website to be up</label>
                        <select
                            id="Timeframe"
                            name="Timeframe"
                            value={formData.Timeframe}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            required
                            className="form-control"
                        >
                            <option value="">Select an option</option>
                            <option value="Less than a month">Less than a month</option>
                            <option value="1-2 months">1-2 months</option>
                            <option value="2-6 months">2-6 months</option>
                            <option value="Up to a year">Up to a year</option>
                        </select>
                    </div>
                );
            case 'Hosting':
                return (
                    <div className="form-group">
                        <label>Do you own a .COM or have your own domain currently?</label>
                        <div className="radio-group">
                            <div className="radio-item">
                                <input
                                    type="radio"
                                    id="HostingYes"
                                    name="Hosting"
                                    value="yes"
                                    checked={formData.Hosting === true}
                                    onChange={handleChange}
                                    required
                                />
                                <label htmlFor="HostingYes">Yes</label>
                            </div>
                            <div className="radio-item">
                                <input
                                    type="radio"
                                    id="HostingNo"
                                    name="Hosting"
                                    value="no"
                                    checked={formData.Hosting === false}
                                    onChange={handleChange}
                                    required
                                />
                                <label htmlFor="HostingNo">No</label>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <section id="getstarted">
            <div className={"getstarted-section fluid"}>
                {isPreview ? (
                    <div className="preview-container">
                        <div className="preview">
                            <h2>Preview Your Information</h2>
                            <p><strong>Name:</strong> {formData.Name}</p>
                            <p><strong>Email:</strong> {formData.Email}</p>
                            <p><strong>Phone Number:</strong> {formData.Phone}</p>
                            <p><strong>Business Type:</strong> {formData.BusinessType}</p>
                            <p><strong>Business Description:</strong> {formData.BusinessUse}</p>
                            <p><strong>Website Goals:</strong> {formData.Goals}</p>
                            <p><strong>Do you have a Slogan?:</strong> {formData.Slogan ? 'Yes' : 'No'}</p>
                            <p><strong>Do you have a Logo?:</strong> {formData.Logo ? 'Yes' : 'No'}</p>
                            <p><strong>How much content do you have?:</strong> {formData.Content}</p>
                            <p><strong>Do you have a color scheme:</strong> {formData.Colors ? 'Yes' : 'No'}</p>
                            <p><strong>What is the timeframe that you need the website up:</strong> {formData.Timeframe}</p>
                            <p><strong>Do you own a .COM(Domain):</strong> {formData.Hosting ? 'Yes' : 'No'}</p>
                            <button className="btn btn-primary" onClick={handleFinalSubmit}>Yes, Submit</button>
                            <button className="btn btn-secondary" onClick={handleEdit}>No, Go Back</button>
                        </div>
                    </div>
                ) : (
                    <form className={"getstarted-form"} onSubmit={handleNextStep}>
                        <Container fluid className="get-started-form-header">
                            <h3>Get a free quote today!</h3>
                            <p>- Responsive and Mobile Website Designs</p>
                            <p>- Get your website up and running in as little as 7 days</p>
                        </Container>
                        {renderStep()}
                        <div className="form-navigation">
                            {currentStep < steps.length - 1 && (
                                <Button variant="primary" type="submit">
                                    Next
                                </Button>
                            )}
                            {currentStep === steps.length - 1 && (
                                <Button variant="primary" onClick={handleNextStep}>
                                    Preview
                                </Button>
                            )}
                        </div>
                    </form>
                )}
            </div>
        </section>
    );
};

export default GetStartedForm;
