import React from 'react';
import { Container } from 'react-bootstrap';
import './App.css'; // Import the CSS file
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import '@popperjs/core';
import './media.css';

const Thanks = () => {
    return(
        <Container fluid className="thanks-wrapper">
            <Container fluid className="thankyou">
                <h1>
                    Thank You!
                </h1>
                <p>Thank you so much for visiting our website.</p>
                <p> The form has been successfully submitted and sent.</p>
                <p>We will be contacting you through the email you provided shortly</p>
                <a href="/" target="_blank">
                    <button className="thanks-button">Return to Stick2YourDreams</button>
                </a>
            </Container>
        </Container>
    )
}

export default Thanks;