import React from 'react';
import { Helmet } from 'react-helmet';
import './App.css'; // Import the CSS file
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import '@popperjs/core';
import './media.css';

const MetaSubscribe = () => {
    const imageUrl = 'https://www.stick2yourdreams.com/fulllogo.png';
    const faviconUrl = 'https://www.stick2yourdreams.com/logo.png'; // Ensure this path is correct
    const appleIconUrl = 'https://www.stick2yourdreams.com/custom-icon.png'; // Ensure this path is correct

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="author" content="Stick2YourDreams" />
            <link rel="icon" href={faviconUrl} type="image/png" />
            <link rel="apple-touch-icon" href={appleIconUrl} type="image/png" />
            <meta
                name="description"
                content="Website design and hosting services providing excellent custom support and integrated mobile optimization."
            />
            <meta
                name="keywords"
                content="website design, website hosting, content creation, business website, affordable, responsive, mobile friendly, professional, seo, business, custom, get started"
            />
            <meta name="robots" content="index, follow" />
            <meta name="googlebot" content="index, follow" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Stick2YourDreams | Subscribe" />
            <meta name="twitter:description" content="Website Design and Hosting Services" />
            <meta name="twitter:image" content={imageUrl} />
            <meta name="twitter:image:alt" content="Stick2YourDreams Logo" />
            <meta property="og:title" content="Stick2YourDreams | Subscribe" />
            <meta property="og:description" content="Website Design and Hosting Services" />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:image:secure_url" content={imageUrl} />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1280" />
            <meta property="og:image:height" content="1024" />
            <meta property="og:url" content="https://www.stick2yourdreams.com" />
            <meta property="og:type" content="website" />
            <meta name="google-site-verification" content="m9kK9Myl156EPJcy8G5mjLwI4GzT2tRWfnyySLIeCJU" />
            <title>Stick2YourDreams | Subscribe</title>
        </Helmet>
    );
};

export default MetaSubscribe;