import React from "react";
import { Container } from 'react-bootstrap';

const FooterSection = () => {
    return (
        <Container fluid className={"footer"}>
            <p>Copyright 2024 @ Stick2YourDreams. All Rights Reserved.</p>
        </Container>
    );
};

export default FooterSection;