import React, { useEffect, } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import '@popperjs/core';
import './media.css';
import CustomNavbar2 from './Navbar.js';
import FooterSection from './footer.js';
import Form from './get-started-form';
import MetaGetStarted from './meta-getstarted.js';

const GetStarted = () => {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <React.Fragment>
            <MetaGetStarted />
            <CustomNavbar2/>
            <Form />
            <FooterSection />
        </React.Fragment>
    );
};

export default GetStarted;