import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Card, CardBody, CardTitle, CardText, Carousel, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import logo from './images/logo.png';
import slide1 from './images/Dreams.jpg';
import slide2 from './images/nostress.jpg';
import slide3 from './images/VSEO.jpg';
import fb from './images/facebook.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import '@popperjs/core';
import './media.css';
import Meta from './meta.js';
import service1 from "./images/Design.jpg";
import service2 from "./images/Website_Hosting.jpg";
import service3 from "./images/content_creation.jpg";
import service4 from "./images/SEO.jpg";
import aboutvideo from './videos/aboutvideo.mp4';

gsap.registerPlugin(ScrollTrigger);

const CustomNavbar = () => {
    const [showContact, setShowContact] = useState(false);

    const handleToggle = () => {
        setShowContact(!showContact);
    };

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/subscribe');
    };

    const handleGetStartedClick = () => {
        navigate('/get-started');
    };

    return (
        <Container fluid className="navbar-container">
            <Navbar className="navbar-drop" collapseOnSelect expand="lg" bg="light" variant="light" fixed="top">
                <Navbar.Brand as={Link} className="navbar-brand" to="/">
                    <img className="logo responsive" src={logo} alt="Logo"/>
                </Navbar.Brand>
                <p className="navbar-brand-text">Stick2YourDreams</p>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ul-nav">
                        <Nav.Link as={Link} className="navbar-nav" to="/">Home</Nav.Link>
                        <Nav.Link as={Link} className="navbar-nav" to="/#services">Services</Nav.Link>
                        <Nav.Link as={Link} className="navbar-nav" to="/#about">About</Nav.Link>
                        <NavDropdown title="Contact" id="collasible-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/get-started">Get Started</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/#contact">General Questions</NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <div className="dropdown-item" style={{padding: '0'}}>
                                <button onClick={handleButtonClick}>
                                    Subscribe
                                </button>
                            </div>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
                <button className="navbar-button" onClick={handleGetStartedClick}>Get Started Today!</button>
            </Navbar>
        </Container>
    );
};


const CarouselSection = () => {
    useEffect(() => {

        const text = document.querySelector('.wavy');
        const textContent = "Create Your Dream Website - Your Vision is Our Mission";
        const duration = 0.10; // Duration for each character to appear
        const stayDuration = 50; // Duration for the text to stay on screen
        const clearDuration = 0; // Duration for clearing the text

        // Clear existing text content
        text.textContent = '';

        // Create a timeline for the typewriter effect
        const tl = gsap.timeline({ repeat: -1 });
        for (let i = 0; i < textContent.length; i++) {
            tl.to(text, {
                textContent: textContent.slice(0, i + 1), // Update text content up to the current character
                duration,
                ease: 'power1.inOut',
            });
        }
        tl.to(text, { duration: stayDuration, delay: duration }); // Stay on screen for 50 seconds
        tl.to(text, { textContent: '', duration: clearDuration }); // Clear the text and immediately start over

        const resizeImages = () => {
            const images = document.querySelectorAll('.carousel-item img');
            images.forEach(img => {
                if (window.innerWidth < 768) {
                    img.style.width = '100%';
                    img.style.height = 'auto';
                    img.style.opacity = '0.20';
                } else if (window.innerWidth >= 768 && window.innerWidth < 1480) {
                    img.style.width = '100%';
                    img.style.height = 'auto';
                    img.style.opacity = '1';
                } else {
                    img.style.width = 'auto';
                    img.style.height = '60vh';
                    img.style.opacity = '1.0';
                }
            });
        };

        resizeImages();
        window.addEventListener('resize', resizeImages);
        return () => {
            window.removeEventListener('resize', resizeImages);
        };
    }, []);

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/get-started');
    };

    return (
        <section id="section1">
            <Carousel fluid className="custom-carousel" interval={10000} indicators={false}>
                <Carousel.Item>
                    <img className="d-block w-100" src={slide1} alt="First slide"/>
                    <Carousel.Caption>
                        <Container fluid className="carousel-words-position">
                            <h3>Bring Your Business Dreams to Life!</h3>
                            <p>Partner with Stick2YourDreams to create and design the website of your dreams.
                                With innovative ideas and reliable service,
                                we're here to make your vision a reality. Your success is our passion!</p>
                            <button onClick={handleButtonClick}>Get Started Now!</button>
                        </Container>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block w-100" src={slide2} alt="Second slide"/>
                    <Carousel.Caption>
                        <Container fluid className="carousel-words-position">
                            <h3>Stress-Free Website Design</h3>
                            <p>Concentrate on your business strategies while we craft a stunning and tailored website to
                                meet your unique needs. Let us handle the details so you can focus on what you do best.</p>
                            <button onClick={handleButtonClick}>Get Started Now!</button>
                        </Container>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block w-100" src={slide3} alt="Third slide"/>
                    <Carousel.Caption>
                        <Container fluid className="carousel-words-position">
                            <h3>Boost Your Visibility on Google</h3>
                            <p>Unlock your website's potential with increased visibility on search engines like Google and
                                Bing. Attract the traffic your business needs to thrive and reach new heights.</p>
                            <button onClick={handleButtonClick}>Get Started Now!</button>
                        </Container>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            <Container fluid className="tag">
                <p className="wavy">
                    <svg viewBox="0 0 240 80" xmlns="http://www.w3.org/2000/svg">
                        <text x="20" y="35" className="small">Build</text>
                        <text x="40" y="35" className="heavy">Your</text>
                        <text x="55" y="55" className="small">Best</text>
                        <text x="65" y="55" className="Rrrrr">Website,</text>
                        <text x="65" y="55" className="Rrrrr">Your</text>
                        <text x="65" y="55" className="Rrrrr">Vision</text>
                        <text x="65" y="55" className="Rrrrr">is</text>
                        <text x="65" y="55" className="Rrrrr">Our</text>
                        <text x="65" y="55" className="Rrrrr">Mission</text>
                    </svg>
                </p>
            </Container>
        </section>
    );
};

const ServicesSection = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/get-started');
    };

    return (
        <section id="services">
            <Container fluid className="services">
                <h1>SERVICES</h1>
                <Container fluid className={"card-container1"}>
                    <Card className="service1">
                        <video autoPlay muted loop id="about-video">
                            <source src={aboutvideo} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                        <CardBody className={"service1card"}>
                            <CardTitle><h3>Website Design & Development</h3></CardTitle>
                            <CardText><p>
                                Before we start creating your dream website, we make sure to lay a
                                solid foundation for success. Once we have a strong plan in place, we can dive into the
                                fun part: bringing your vision to life.</p>
                                <button className="firstandthird" onClick={handleButtonClick}>Get Started Today!
                                </button>
                            </CardText>
                            <img src={service1} alt="Service 1" className="service1-image"/>
                        </CardBody>
                    </Card>
                    <Card className="service2">
                        <video autoPlay muted loop id="about-video">
                            <source src={aboutvideo} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                        <CardBody className={"service2card"}><img src={service2} alt="Service 2"
                                                                  className="service2-image"/>
                            <CardTitle><h3>Website Hosting</h3></CardTitle>
                            <CardText><p>Location, location, location. Discover the ideal home for your website,
                                ensuring it remains safe, secure, and lightning-fast. Let us help you find a hosting
                                solution or remove all stress and we'll host your website for you.</p>
                                <button className="secondandfourth" onClick={handleButtonClick}>Get Started Today!</button>
                            </CardText>
                        </CardBody>
                    </Card>
                    <Card className="service3">
                        <video autoPlay muted loop id="about-video">
                            <source src={aboutvideo} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                        <CardBody className={"service3card"}><img src={service3} alt="Service 3"
                                                                  className="service3-image"/>
                            <CardTitle><h3>Content Creation</h3></CardTitle>
                            <CardText><p>Have a general plan for what you want, but don't know what
                                to put in. We can help you along the path with our multitude of tools at our disposal,
                                we can find the perfect look for your site, together.
                                Making your life a little less stressful.</p>
                                <button className="firstandthird" onClick={handleButtonClick}>Get Started Today!</button>
                            </CardText>
                        </CardBody>
                    </Card>
                    <Card className="service4">
                        <video autoPlay muted loop id="about-video">
                            <source src={aboutvideo} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                        <CardBody className={"service4card"}><img src={service4} alt="Service 4"
                                                                  className="service4-image"/>
                            <CardTitle><h3>Search Engine Optimization (SEO)</h3></CardTitle>
                            <CardText><p>Do you want to be seen on Google? How about the first page? The
                                better the SEO, the more traffic your website will receive, and the
                                better your business will perform.</p>
                                <button className="secondandfourth" onClick={handleButtonClick}>Get Started Today!</button>
                            </CardText>
                        </CardBody>
                    </Card>
                </Container>
            </Container>
        </section>
    )
}


const AboutSection = () => {
    useEffect(() => {
        gsap.to(".aboutsection", {
            backgroundPositionY: "15%", // Adjust for a smoother effect
            ease: "power3.inOut", // Smooth easing
            scrollTrigger: {
                trigger: ".aboutsection",
                start: "50% 90%",
                end: "bottom 20%",
                scrub: 1, // Smoother scrubbing
                toggleActions: "play none none none", // Ensures the animation does not reset
            }
        });
    }, []);


    return (
        <section id="about">
            <Container fluid className="aboutsection">
                <h1>Tinker, Runner, Designer</h1>
                <Container className="aboutsectionleft">
                    <Card className="about">
                        <CardBody className="aboutcard">
                            <CardTitle>
                                <h3>Purpose</h3>
                            </CardTitle>
                            <CardText>
                                <p>
                                    Let's build your dream website to ensure your business stands out and gets noticed.
                                    We'll create a clear and concise site that delivers exactly what your visitors need.
                                    Then, I'll add a touch of flair to make your website truly eye-catching.
                                </p>
                            </CardText>
                        </CardBody>
                    </Card>
                    <Card className="about">
                        <CardBody className="aboutcard">
                            <CardTitle>
                                <h3>Relationship</h3>
                            </CardTitle>
                            <CardText>
                                <p>
                                    I’m dedicated to crafting your dream website, ensuring your business shines.
                                    Your site will be clear and straightforward, giving visitors the information they
                                    need.
                                    Then, I’ll add striking visual elements to make your website unforgettable.
                                </p>
                            </CardText>
                        </CardBody>
                    </Card>
                    <Card className="about">
                        <CardBody className="aboutcard">
                            <CardTitle>
                                <h3>Fun</h3>
                            </CardTitle>
                            <CardText>
                                <p>
                                    Creating websites is my passion, and I’m excited to offer you an unforgettable,
                                    stunning, and affordable website. Let me do what I love and give your
                                    business the exceptional online presence it deserves.
                                </p>
                            </CardText>
                        </CardBody>
                    </Card>
                </Container>
                <Container fluid className="aboutsectionoverlay"></Container>
                <Container className="aboutsectionright">
                    <p>
                        Nice to meet you! Doing what you love is rare,
                        but I truly love creating dream websites for amazing people like you.
                        My passion for helping others inspired me to turn this love into a thriving business.
                        If I could, I’d build websites all day, every day. Let’s work together
                        to get your business seen by millions.
                    </p>
                </Container>
            </Container>
        </section>
    );
};


const ContactSection = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const formatPhoneNumber = (value) => {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;

        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phone') {
            setFormData({
                ...formData,
                [name]: formatPhoneNumber(value)
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!formData.name || !formData.email || !formData.phone || !formData.message || formData.pages === 'Select an item') {
            alert('Please fill in all fields.');
            return;
        }

        fetch('https://formspree.io/f/xkndywgn', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        }).then(response => {
            if (response.ok) {
                alert('Email sent successfully!\n' +
                    'We will be contacting you through the email that you provided very soon.\n' +
                    'Thank you for choosing Stick2YourDreams');
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                });
            } else {
                alert('There was an error sending the email. Please try again later.');
            }
        }).catch(error => {
            console.error('Error sending email:', error);
            alert('There was an error sending the email. Please try again later.');
        });
    };

    return (
        <section id="contact">
            <div className={"contact-section fluid"}>
                <h1>CHAT WITH ME</h1>
                <div className="scrolling-banner">
                    Get your free quote today - Get your website up and running in as little as 7 days
                </div>
                <form className={"contact-form"} onSubmit={handleSubmit}>
                    <div className={"form-group"}>
                        <label htmlFor="name">Name:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            className={"form-control"}
                        />
                    </div>
                    <div className={"form-group"}>
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            className={"form-control"}
                        />
                    </div>
                    <div className={"form-group"}>
                        <label htmlFor="phone">Phone Number:</label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                            className={"form-control"}
                        />
                    </div>
                    <div className={"form-group"}>
                        <label htmlFor="message">General Questions</label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                            className={"form-control"}
                        />
                    </div>
                    <button className="contact-btn" type="submit">Send Email</button>
                    <div className="contact-wrap">
                        <div className="contact-wrap-content">
                            <p>Stick2YourDreams, LLC </p>
                            <p>Email: jasonadams07@hotmail.com</p>
                            <div className="facebook2">
                                <a href="https://www.facebook.com/profile.php?id=61558807655294" target="_blank"
                                   rel="noopener noreferrer">
                                    <img src={fb} alt="facebook"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
};

const MyPage = () => {
    useEffect(() => {
        const pages = document.querySelectorAll('.page');

        pages.forEach((page) => {
            page.addEventListener('click', () => {
                if (page.classList.contains('left')) {
                    page.classList.remove('left');
                    page.classList.add('right');
                } else {
                    page.classList.remove('right');
                    page.classList.add('left');
                }
            });
        });

        return () => {
            pages.forEach((page) => {
                page.removeEventListener('click', () => {});
            });
        };
    }, []);

    return (
        <div>
            <Meta />
            <CustomNavbar />
            <CarouselSection />
            <ServicesSection />
            <AboutSection />
            <ContactSection />
            <FooterSection />
        </div>
    );
};

const Home = () => {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <div className={"Home"}>
            <MyPage />
        </div>
    );
};

const FooterSection = () => {
    return (
        <footer className={"footer"}>
            <p>Copyright 2024 @ Stick2YourDreams. All Rights Reserved.</p>
        </footer>
    );
};

export default Home;