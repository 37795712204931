import React from "react";
import './App.css'; // Assuming you move your CSS to a separate file

const SubscribeForm = () => {
    return (
        <div className="subscribe-container">
            <div className="subscribe">
                <form action="https://stick2yourdreams.us14.list-manage.com/subscribe/post?u=06f7b59ef0a7a24aecd5d4cf4&amp;id=6a7f42f221&amp;f_id=0006fbe0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
                    <div className="subscribe-form">
                        <h2>Subscribe</h2>
                        <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
                        <div className="mc-field-group">
                            <label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span></label>
                            <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" required />
                        </div>
                        <div className="mc-field-group">
                            <label htmlFor="mce-FNAME">First Name <span className="asterisk">*</span></label>
                            <input type="text" name="FNAME" className="required text" id="mce-FNAME" required />
                        </div>
                        <div className="mc-field-group">
                            <label htmlFor="mce-LNAME">Last Name <span className="asterisk">*</span></label>
                            <input type="text" name="LNAME" className="required text" id="mce-LNAME" required />
                        </div>
                        <div className="mc-field-group">
                            <label htmlFor="mce-PHONE">Phone Number </label>
                            <input type="text" name="PHONE" className="REQ_CSS" id="mce-PHONE" />
                        </div>
                        <div hidden><input type="hidden" name="tags" value="11238657" /></div>
                        <div id="mce-responses" className="clear">
                            <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                            <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                        </div>
                        <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
                            <input type="text" name="b_06f7b59ef0a7a24aecd5d4cf4_6a7f42f221" tabIndex="-1" />
                        </div>
                        <div className="clear">
                            <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value="Subscribe" />
                        </div>
                    </div>
                </form>
            </div>
            <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>
            <script type="text/javascript">
                {`(function($) {window.fnames = new Array(); window.ftypes = new Array();
                fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';
                fnames[2]='LNAME';ftypes[2]='text';fnames[4]='PHONE';ftypes[4]='phone';
                fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));
                var $mcj = jQuery.noConflict(true);`}
            </script>
        </div>
    );
};

export default SubscribeForm;